<section class="main-container">
	<div
		class="card overflow-hidden"
		[ngClass]="
			theme == CardsTheme.DARK
				? 'card-background-dark'
				: 'card-background-light'
		"
	>
		<div class="text-container">
			<p
				[countUp]="num_users"
				[duration]="animationDuration"
				class="cifra montserrat xs:cifras-eb-60 sm:cifras-eb-70 md:cifras-eb-50 lg:cifras-eb-60 xl:cifras-eb-80"
				[ngClass]="
					theme == CardsTheme.LIGHT ? 'dark-text' : 'light-text'
				"
			></p>
			<div class="flex subtext">
				<img
					[src]="
						theme == CardsTheme.LIGHT ? usuariosDark : usuariosLight
					"
				/>
				<p
					class="poppins xs:p-m-20 sm:p-m-25 md:p-m-16 lg:p-m-20 xl:p-m-25 title"
					[ngClass]="
						theme == CardsTheme.LIGHT ? 'dark-text' : 'light-text'
					"
				>
					{{ "shared.ktisMessage.usersMessage" | translate }}
				</p>
			</div>
		</div>
	</div>
	<div
		class="card overflow-hidden"
		[ngClass]="
			theme == CardsTheme.DARK
				? 'card-background-dark'
				: 'card-background-light'
		"
	>
		<div class="text-container">
			<p
				[countUp]="num_jobs"
				[duration]="animationDuration"
				class="cifra montserrat xs:cifras-eb-60 sm:cifras-eb-70 md:cifras-eb-50 lg:cifras-eb-60 xl:cifras-eb-80"
				[ngClass]="
					theme == CardsTheme.LIGHT ? 'dark-text' : 'light-text'
				"
			></p>
			<div class="flex subtext">
				<img
					[src]="
						theme == CardsTheme.LIGHT ? trabajosDark : trabajosLight
					"
				/>
				<p
					class="poppins xs:p-m-20 sm:p-m-25 md:p-m-16 lg:p-m-20 xl:p-m-25 title"
					[ngClass]="
						theme == CardsTheme.LIGHT ? 'dark-text' : 'light-text'
					"
				>
					{{ "shared.ktisMessage.jobsMessage" | translate }}
				</p>
			</div>
		</div>
	</div>
	<div
		class="card overflow-hidden"
		[ngClass]="
			theme == CardsTheme.DARK
				? 'card-background-dark'
				: 'card-background-light'
		"
	>
		<div class="text-container">
			<p
				[countUp]="num_evals"
				[duration]="animationDuration"
				class="cifra montserrat xs:cifras-eb-60 sm:cifras-eb-70 md:cifras-eb-50 lg:cifras-eb-60 xl:cifras-eb-80"
				[ngClass]="
					theme == CardsTheme.LIGHT ? 'dark-text' : 'light-text'
				"
			></p>
			<div class="flex subtext">
				<img
					[src]="
						theme == CardsTheme.LIGHT
							? evaluacionesDark
							: evaluacionesLight
					"
				/>
				<p
					class="poppins xs:p-m-20 sm:p-m-25 md:p-m-16 lg:p-m-20 xl:p-m-25 title"
					[ngClass]="
						theme == CardsTheme.LIGHT ? 'dark-text' : 'light-text'
					"
				>
					{{ "shared.ktisMessage.evalsMessage" | translate }}
				</p>
			</div>
		</div>
	</div>
</section>
