import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { GeneralButtonComponent } from './general-button/general-button.component';
import { GoogleTagManagerComponent } from './google-tag-manager/google-tag-manager.component';
import { NavbarComponent } from './navbar/navbar.component';
import { CarrouselEmpresasComponent } from './carrousel-empresas/carrousel-empresas.component';
import { TranslateModule } from '@ngx-translate/core';
import { GeneralCardComponent } from './general-card/general-card.component';
import { CifrasEmpleoComponent } from './cifras-empleo/cifras-empleo.component';
import { FooterComponent } from './footer/footer.component';
import { KtisComponent } from './ktis/ktis.component';
import { CookieConsentModalComponent } from './cookie-consent-modal/cookie-consent-modal.component';
import { RouterModule } from '@angular/router';
import { HeaderTwsComponent } from './header-tws/header-tws.component';
import { CountUpDirective } from '../../directives/count-up.directive';
import { BlogCardComponent } from './blog-card/blog-card.component';
import { CreateProcessModalComponent } from './create-process-modal/create-process-modal.component';
import { GeneralDropdownComponent } from './general-dropdown/general-dropdown.component';
import { PaginationComponent } from './pagination/pagination.component';
import { BlogJobSectionComponent } from './blog/blog-job-section.component';

@NgModule({
	declarations: [
		GeneralButtonComponent,
		NavbarComponent,
		CarrouselEmpresasComponent,
		GoogleTagManagerComponent,
		GeneralCardComponent,
		CifrasEmpleoComponent,
		FooterComponent,
		KtisComponent,
		HeaderTwsComponent,
		CookieConsentModalComponent,
		CountUpDirective,
		BlogCardComponent,
		CreateProcessModalComponent,
		GeneralDropdownComponent,
		PaginationComponent,
		BlogJobSectionComponent,
	],
	imports: [CommonModule, TranslateModule, RouterModule],
	exports: [
		NavbarComponent,
		GeneralButtonComponent,
		CarrouselEmpresasComponent,
		GoogleTagManagerComponent,
		GeneralCardComponent,
		FooterComponent,
		KtisComponent,
		HeaderTwsComponent,
		CookieConsentModalComponent,
		CifrasEmpleoComponent,
		BlogCardComponent,
		CreateProcessModalComponent,
		GeneralDropdownComponent,
		PaginationComponent,
		BlogJobSectionComponent
	],
	providers: [DecimalPipe],
})
export class SharedModule {}
