<section class="main-container bg-MoradoClaro">
	<div class="blog-container">
		<div class="text-teal-900 text-[50px] font-extrabold font-['Montserrat'] xs:h-2-eb-30 sm:h-2-eb-50 md:h-2-eb-40 lg:h-2-eb-50">
			BLOG
		</div>
		<div class="middle-container">
			<div class="cards-container">
				<a [href]="posts[0].postUrl ? '/post/' + posts[0].postUrl : ''">
					<div class="card primary-card">
						<img class="object-cover image-primary rounded-[15px]" src="{{ posts[0].postImageUrl }}" />
						<div class="primary-text-field">
							<span class="primary-text xs:h-4-eb-20 sm:h-4-eb-20 lg:h-4-eb-25 text-teal-900 font-['Montserrat']">
								{{ posts[0].postTitle }}<br /><br />
							</span>
							<span class="sub-text text-teal-900 text-xl font-medium font-['Poppins'] line-clamp-4">
								{{ posts[0].postMetadescription }}
							</span>
						</div>
					</div>
				</a>
				<a [href]="posts[1].postUrl ? '/post/' + posts[1].postUrl : ''">
					<div class="card primary-card">
						<img class="object-cover image-primary rounded-[15px]" src="{{ posts[1].postImageUrl }}" />
						<div class="primary-text-field">
							<span
								class="truncate-text primary-text xs:h-4-eb-20 sm:h-4-eb-20 lg:h-4-eb-25 text-teal-900 font-['Montserrat']">
								{{ posts[1].postTitle }}<br /><br />
							</span>
							<span class="sub-text text-teal-900 text-xl font-medium font-['Poppins'] line-clamp-4">
								{{ posts[1].postMetadescription }}
							</span>
						</div>
					</div>
				</a>
				<a [href]="posts[2].postUrl ? '/post/' + posts[2].postUrl : ''">
					<div class="card secondary-card">
						<img class="object-cover image-secondary rounded-[15px]" src="{{ posts[2].postImageUrl }}" />
						<div class="secondary-text-field p-m-16 sm:p-m-16 md:p-m-20 lg:p-m-16 xl:p-m-20 text-teal-900 font-['Montserrat']">
							{{ posts[2].postTitle }}
						</div>
					</div>
				</a>
				<a [href]="posts[3].postUrl ? '/post/' + posts[3].postUrl : ''">
					<div class="card secondary-card">
						<img class="object-cover image-secondary rounded-[15px]" src="{{ posts[3].postImageUrl }}" />
						<div class="secondary-text-field p-m-16 sm:p-m-16 md:p-m-20 lg:p-m-16 xl:p-m-20 text-teal-900 font-['Montserrat']">
							{{ posts[3].postTitle }}
						</div>
					</div>
				</a>
				<a [href]="posts[4].postUrl ? '/post/' + posts[4].postUrl : ''">
					<div class="card secondary-card">
						<img class="object-cover image-secondary rounded-[15px]" src="{{ posts[4].postImageUrl }}" />
						<div class="secondary-text-field p-m-16 sm:p-m-16 md:p-m-20 lg:p-m-16 xl:p-m-20 text-teal-900 font-['Montserrat']">
							{{ posts[4].postTitle }}
						</div>
					</div>
				</a>
				<a [href]="posts[5].postUrl ? '/post/' + posts[5].postUrl : ''">
					<div class="card secondary-card">
						<img class="object-cover image-secondary rounded-[15px]" src="{{ posts[5].postImageUrl }}" />
						<div
							class="truncate-last-card secondary-text-field p-m-16 sm:p-m-16 md:p-m-20 lg:p-m-16 xl:p-m-20 text-teal-900 font-['Montserrat']">
							{{ posts[5].postTitle }}
						</div>
					</div>
				</a>
			</div>
		</div>
	</div>
</section>
<div  class="flex justify-center pb-[4.438rem]">
	<button [routerLink]="['/' + blogUrl.Base]"
		class="rounded-[2.25rem] gap-[0.625rem] px-4 py-2 my-3 border-[0.1875rem] border-[#004751] font-montserrat font-extrabold text-xl text-[#004751] sm:w-[21.8125rem] w-[8.75rem] hover:!bg-[#DBD1F8]">
		<span >{{ "jobViews.blog.seeMore" | translate  }}</span>
	</button>
</div>
