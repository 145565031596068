<!-- Footer container -->
<footer class="w-full" [class]="backgroundColorClass">
	<div
		class="bg-VerdeOscuro xxl:pt-[4.125rem] py-10 main-container text-[#F2F2F2] flex flex-col"
	>
		<img
			class="w-[13.125rem] h-[7.25rem] sm:h-[6.9375rem] object-cover logo mb-5"
			src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2FLogo-TWS.png?alt=media"
		/>
		<div class="grid-wrapper flex flex-col">
			<div class="info flex flex-col">

				<div class="flex flex-row">
					<a [routerLink]="blogUrl.Base" class="poppins xs:p-m-16 sm:p-m-16 md:p-m-20 lg:p-m-16 xl:p-m-20 flex items-center">
					<img src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2Fedit.svg?alt=media"
						alt="Blog" class="pr-3">
					Blog The Wise Seeker</a>
				</div>

				<div class="flex flex-row">
					<img
						src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2Fenvelope-icon.svg?alt=media"
						alt="Email"
						class="pr-3"
					/>
					<a (click)="mail()" class="poppins xs:p-m-16 sm:p-m-16 md:p-m-20 lg:p-m-16 xl:p-m-20"
						>info&#64;thewiseseeker.com</a
					>
				</div>
				<div class="flex flex-row">
					<img
						src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2Ftelephone-icon.svg?alt=media"
						alt="Phone number"
						class="pr-3"
					/>
					<a
						href="tel:+34919152400"
						class="poppins xs:p-m-16 sm:p-m-16 md:p-m-20 lg:p-m-16 xl:p-m-20"
						>+34 91 915 24 00</a
					>
				</div>
			</div>
			<div class="rrss">
				<a
					(click)="
						goToLink(
							'https://www.instagram.com/thewiseseeker_oficial/'
						)
					"
				>
					<img
						src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2Finstagram.svg?alt=media"
						alt="Instagram"
						class="w-[2.5rem] sm:w-[3.375rem]"
					/>
				</a>
				<a
					(click)="
						goToLink(
							'https://www.linkedin.com/company/the-wise-seeker/mycompany/'
						)
					"
				>
					<img
						src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2Flinkedin.svg?alt=media"
						alt="Linkedin"
						class="w-[2.5rem] sm:w-[3.375rem]"
					/>
				</a>
				<a (click)="goToLink('https://www.tiktok.com/@thewiseseeker_')">
					<img
						src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2Ftiktok.svg?alt=media"
						alt="TikTok"
						class="w-[2.5rem] sm:w-[3.375rem]"
					/>
				</a>
				<a
					(click)="
						goToLink('https://www.youtube.com/@thewiseseeker_')
					"
				>
					<img
						src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2Fyoutube.svg?alt=media"
						alt="Youtube"
						class="w-[2.5rem] sm:w-[3.375rem]"
					/>
				</a>
				<a (click)="goToLink('https://twitter.com/thewiseseeker_')">
					<img
						src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2Fx.svg?alt=media"
						alt="X"
						class="w-[2.5rem] sm:w-[3.375rem]"
					/>
				</a>
			</div>
			<div class="descubre flex flex-col gap-5">
				<h2 class="montserrat header-8-eb sm:header-5-eb">
					{{ "shared.footer.discoverMessage" | translate }}
				</h2>
				<div class="marketplace-buttons flex flex-row shrink-0">
					<app-general-button
						[text]="'Google Play'"
						[buttonType]="buttonType.BORDE_BLANCO"
						[small]="true"
						(buttonClick)="
							goToLink(
								'https://play.google.com/store/apps/details?id=com.thewiseseeker.mobile'
							)
						"
					></app-general-button>
					<app-general-button
						[text]="'Apple Store'"
						[buttonType]="buttonType.BORDE_BLANCO"
						[small]="true"
						(buttonClick)="
							goToLink(
								'https://apps.apple.com/es/app/the-wise-seeker/id1661036956'
							)
						"
					></app-general-button>
				</div>
			</div>
			<div class="legal flex flex-col gap-5">
				<div>
					<p class="legal-title montserrat xs:h-5-20 sm:h-5-20">
						{{ "shared.footer.legal.titleMessage" | translate }}
					</p>
				</div>
				<div
					class="legal-links flex flex-row justify-between items-start self-stretch montserrat xs:body-6-m sm:body-4-m"
				>
					<a [routerLink]="legalUrl.Base">{{
						"shared.footer.legal.warningMessage" | translate
					}}</a>
					<a [routerLink]="cookiesUrl.Base">{{
						"shared.footer.legal.cookiesPolicyMessage" | translate
					}}</a>
					<button (click)="configCookies()">
						{{
							"shared.footer.legal.cookiesConfigMessage"
								| translate
						}}
					</button>
				</div>
			</div>
		</div>
	</div>
	<div
		class="flex flex-col justify-between items-center bg-white py-5 px-5 w-full"
	>
		<div
			class="flex flex-row xs:gap-[1.625rem] sm:gap-[1.375rem] md:gap-[4.375rem] lg:gap-[1.698rem] xl:gap-[2.781rem] xxl:gap-[5.149rem] xs:w-[22.5rem] sm:w-[36rem] md:w-[48rem] lg:w-[62rem] xl:w-[75rem] xxl:w-[87.5rem] justify-center"
		>
			<div
				class="flex flex-col lg:flex-row xs:gap-[0.688rem] sm:gap-[1.063rem] md:gap-[1.063rem] lg:gap-[1.698rem] xl:gap-[2.781rem] xxl:gap-[5.149rem]"
			>
				<div
					class="flex flex-row xs:gap-[1.861rem] sm:gap-[1.671rem] md:gap-[4.809rem] lg:gap-[1.698rem] xl:gap-[2.781rem] xxl:gap-[5.149rem] justify-end"
				>
					<img
						class="xs:w-[5.786rem] xs:h-[1.375rem] sm:w-[9.995rem] sm:h-[2.375rem] md:w-[11.836rem] md:h-[2.813rem] lg:w-[11.573rem] lg:h-[2.75rem] xl:w-[13.151rem] xl:h-[3.125rem] xxl:w-[13.151rem] xxl:h-[3.125rem] object-contain"
						src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2Funion-europea.svg?alt=media"
						alt=""
					/>
					<img
						class="xs:w-[8.658rem] xs:h-[1.375rem] sm:w-[14.955rem] sm:h-[2.375rem] md:w-[17.709rem] md:h-[2.813rem] lg:w-[7.472rem] lg:h-[2.75rem] xl:w-[8.491rem] xl:h-[3.125rem] xxl:w-[8.491rem] xxl:h-[3.125rem] hidden lg:block object-contain"
						src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2Frtyr.svg?alt=media"
						alt=""
					/>
					<img
						class="xs:w-[8.658rem] xs:h-[1.375rem] sm:w-[14.955rem] sm:h-[2.375rem] md:w-[17.709rem] md:h-[2.813rem] lg:hidden object-contain"
						src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2Frtyr-horizontal.svg?alt=media"
						alt=""
					/>
				</div>
				<div
					class="flex flex-row xs:gap-[1.806rem] sm:gap-[1.578rem] md:gap-[4.698rem] lg:gap-[1.698rem] xl:gap-[2.781rem] xxl:gap-[5.149rem]"
				>
					<img
						class="xs:w-[6.679rem] xs:h-[1.375rem] sm:w-[11.536rem] sm:h-[2.375rem] md:w-[13.661rem] md:h-[2.813rem] lg:w-[13.357rem] lg:h-[2.75rem] xl:w-[15.179rem] xl:h-[3.125rem] xxl:w-[15.179rem] xxl:h-[3.125rem] object-contain"
						src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2Finnvierte.svg?alt=media"
						alt=""
					/>
					<img
						class="xs:w-[7.821rem] xs:h-[1.375rem] sm:w-[13.508rem] sm:h-[2.375rem] md:w-[15.996rem] md:h-[2.813rem] lg:w-[15.641rem] lg:h-[2.75rem] xl:w-[17.774rem] xl:h-[3.125rem] xxl:w-[19.551rem] xxl:h-[3.438rem] object-contain"
						src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2FCDTI2023.svg?alt=media"
						alt=""
					/>
				</div>
			</div>
			<img
				class="xs:w-[3.319rem] xs:h-[2.813rem] sm:w-[5.754rem] sm:h-[4.875rem] md:w-[6.271rem] md:h-[5.313rem] lg:w-[5.164rem] lg:h-[4.375rem] xl:w-[5.533rem] xl:h-[4.688rem] xxl:w-[5.533rem] xxl:h-[4.688rem] object-contain"
				src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2Fenisa.svg?alt=media"
				alt=""
			/>
		</div>
		<p
			class="rights montserrat text-center xs:mt-[1.313rem] sm:mt-[1.875rem] md:mt-[1.938rem] lg:mt-[0.688rem] xl:mt-[0.375rem] xxl:mt-[0.375rem]"
		>
			{{ "shared.footer.rightsMessage" | translate }}
		</p>
	</div>
</footer>
